<template lang="pug">
    div
        social-sharing(title='I ♥♥♥ Biathlon', url='https://biathlon.live', inline-template='')
            div
                #moreDropdown.navbar-dropdown
                    a.navbar-item
                        network(network='twitter')
                            i.fab.fa-twitter
                                | &nbsp;Twitter
                    a.navbar-item
                        network(network='reddit')
                            i.fab.fa-reddit
                                | &nbsp;Reddit
                    a.navbar-item
                        network(network='vk')
                            i.fab.fa-vk
                                | &nbsp;VKontakte
                    a.navbar-item
                        network(network='whatsapp')
                            i.fab.fa-whatsapp
                                | &nbsp;Whatsapp
</template>

<script>

    export default {
        props: []
    }
</script>
