import Vue from 'vue/dist/vue.esm'

import SocialSharing from 'vue-social-sharing'
Vue.use(SocialSharing);

import MenuSocial from '../components/MenuSocial'

// import library.js - custom functions and methods
import './library'

document.addEventListener('DOMContentLoaded', () => {
    let element = document.getElementById("menusocial")


    const app = new Vue({
        el: element,
        data: {

        },
        template: '<MenuSocial />',
        components: {MenuSocial},
    })
})
